#header-container {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    padding: 1rem;
    border-radius: 1rem;
}
#header-container .title-content {
    display: flex;
    flex-direction: column;
}
#header-container .history-filter-content {
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

@media (max-width: 500px) {
    #header-container .history-filter-content {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
    }
    #header-container .history-filter-content .filter-itens{
        width: 100%;
        margin-top: 0.5rem;
    }
}