/* MyAccountTab */
#my-account-tab-container{
    margin: 1rem;
    padding: 0;
}
#my-account-tab-container #mydata-options {
    border-radius: 1.5rem 1.5rem 0 0;
}

#my-account-tab-container .mydata-tab-panel {
    border-radius: 0 0 1.5rem 1.5rem;
}

/* Forms */
.form-content {
    display: flex;
    flex-direction: column;
}
.form-content .textField {
    margin: 0.5rem 0;
}
.form-content .textfield-icon {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 0.5rem;
}
.form-content .textfield-icon .textField-button {
    margin: 0.5rem 0 0.2rem;
    width: 83%;
}
.form-content .textfield-icon .button-mydata {
    margin: 0;
    padding: 0;
    width: 15%;
    height: 4rem;
}

/* ExistAddress */
.form-content .form-title-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 0.5rem;
}
.form-content .form-title-button .button-address-exist {
    margin: 0;
    width: 30%;
}


/* MyAddressTabPanel */
.without-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.without-data .with-data {
    margin: 2rem 1rem 1rem;
}
.without-data p {
    margin: 1rem;
}
.without-data button {
    width: 30%;
}


/* ChangePasswordTabPanel */
.change-password-username {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.change-password-username  p {
    margin: 0 1rem 2rem;
    text-align: center;
    font-size: 1rem;
}
.change-password-username .change-password-field {
    width: 40%;
}
.change-password-username .form-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
}
.change-password-username .form-content .change-password-field  {
    width: 100%;
    margin-bottom: 1rem;
}
.change-password-username .form-content .warning-change-password {
    color: red;
}



@media (max-width: 780px) {
    /* MyAddressTabPanel */
    .without-data button {
        width: 50%;
    }
    .form-content .textfield-icon {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 0.5rem;
    }
    .form-content .textfield-icon .textField-button {
        margin: 0.5rem 0 0.2rem;
        width: 100%;
    }
    .form-content .textfield-icon .button-mydata {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 4rem;
    }
}
@media (max-width: 680px) {
    /* MyAddressTabPanel */
    .change-password-username .change-password-field {
        width: 100%;
    }
    .change-password-username .form-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}
@media (max-width: 580px) {
    /* MyAddressTabPanel */
    .without-data button {
        width: 100%;
    }
}