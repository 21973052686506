
@media (max-width: 500px) {

    #header-container .history-filter-content1 {
        flex-direction: column !important;
    }

    #header-container .div-column {

        display: flex !important;
    }

    #header-container .div-column .filter-itens {
        
        width: 100% !important;
    }
}