
@media (max-width: 500px) {

    .content-form .form .half-div{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .content-form .form .half-input{
        width: 100%;
        margin-bottom: 100px;
    }
}