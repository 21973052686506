/* FormCreateAccount */
#create-account-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#create-account-container p {
    text-align: center;
    margin: 0rem;
    color: rgb(60, 60, 60);
}
#create-account-container #form-content {
    display: flex;
    flex-direction: column;
    min-width: 355px;
}
#create-account-container #form-content .text-input {
    margin-top: 1rem;
}
#create-account-container #form-content .genre-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1rem;
}
#create-account-container #form-content .genre-input {
    width: 100%;
}
#create-account-container #form-content .buttons {
    display: flex;
}

#newaccount-container {

    margin-top: 0rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border:  0px solid;
}
#newaccount-container .form {
    margin-top: 0rem;
    padding: 0.1rem;
    width: 100%;
}
#newaccount-container .form .half-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
#newaccount-container .form .half-input{
    width: 49%;
    margin-bottom: 10px;
}
#newaccount-container .form .full-input {
    padding: .2rem;
    width: 100%;
    margin-bottom: 10px;
}
#newaccount-container .form .button-content{
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 500px) {

    #newaccount-container .form .half-div{

        width: 100%;
        display: flex;  
        justify-content: space-between;
    }
    
    #newaccount-container .form .half-input{

        padding: .2rem;
        width: 100%;
        margin-bottom: 10px;
    }

    #newaccount-container .form .half-input2{
        
        padding: .2rem;
        width: 50%;
        margin-bottom: 10px;
    }
}


/* FormSecurityCode */
.subtitle {
    text-align: center;
}
.subtitle-warning {
    text-align: center;
    font-style: italic;
}


/* ConfirmTermoModal */
#scroll-dialog-description {
    text-align: justify;
}

#dialog-action-term {
    padding-left: 1rem;
    padding-right: 1rem;
}
#dialog-action-term-recuse {
    display: flex;
    flex: 1;
}
#dialog-action-term-accept {
    display: flex; 
    flex: 1;
    justify-items: 'center';
}


/* FormLogin */
#login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#login-container .form {
    margin: 1rem;
    display: grid;
    grid-template-columns: repeat(1);
}
#login-container .form .form-itens {
    margin: 1rem;
    min-width: 300px;
}
#login-container .button-login {
    margin: 1.25rem;
    width: 70%;
}
#login-container .createaccount-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    padding: 1rem;
}
#login-container .button-createaccount {
    border: none;
    background: none;
    font-size: 1rem;
}
#login-container .button-createaccount:hover {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #008698;
    color: #008698;
    background: none;
    font-size: 1rem;
}
#login-container .button-recover-password {
    border: none;
    background: none;
    font-size: 1rem;
    color: #008698;
}
#login-container .button-recover-password:hover {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #008698;
    color: #008698;
    background: none;
    font-size: 1rem;
}


/* RecoverPassword */
#recover-password-username-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0 1rem;
}
#recover-password-username-container p {
    text-align: center;
    margin: 1.5rem 2.5rem;
    color: rgb(60, 60, 60);
}
#recover-password-username-container .input {
    width: 60%;
}
#recover-password-username-container .button-recover {
    width: 60%;
}
#recover-password-username-container .form-content{
    display: flex;
    flex-direction: column;
    align-items: center;
}
#recover-password-username-container .form-content .input{
    width: 100%;
    margin-bottom: 1.5rem;
}
#recover-password-username-container .form-content .textField {
    width: 100%;
    margin: 0.5rem 0;
}


/*  Politica de Privacidade  */
#header {
    display: flex;
    flex-direction: column;
    border-radius: 0rem;
    width: 100%;
    align-items: center;
}

#header-container {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    padding: 1rem;
    border-radius: 0rem;
    width: 100%;
    align-items: left;
    font-size: 1rem;
}

#header-container .policies-title {
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    align-items: center;
    font-weight: bold;
}

#header-container .policies-content {
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

