#header {
    display: flex;
    flex-direction: column;
    border-radius: 0rem;
    width: 100%;
    align-items: center;
    font-family: "Roboto", sans-serif;
}

#header-container {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    padding: 1rem;
    border-radius: 0rem;
    width: 50%;
    align-items: left;
    font-size: 1rem;
}

#header-container .policies-title {
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    align-items: center;
    font-weight: bold;
}

#header-container .policies-content {
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

#header-container .policies-content2 {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    margin-left: 1rem;
}

@media (max-width: 1024px) {

    #header-container  {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        width: 95%;
        align-items: left;
        font-size: .9rem;
    }
}