#header-container {
    display: flex;
    flex-direction: column;
    margin: .5rem;
    padding: .5rem;
    border-radius: .5rem;
}
#header-container .header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
#header-container .header-content .title-content {
    display: flex;
    flex-direction: column;
}
#header-container .button-create-vehicle {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}