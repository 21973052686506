html, body { 
    width: 100vw; 
    height: 100vh; 
    margin: 0;
    padding: 0;
    font-size: .9rem;
}

body {
    font: 00 1rem "Roboto", sans-serif;
    background: #eeece4 ;
}

.body-container {
    background-color: #eeece4;
    padding: 0;
    margin: 0;
}

button {
    cursor: pointer;
}

.button {
    font-size: 1rem;
    margin-top: 20px;
    height: 2.5rem;
    width: 100%;
    border-radius: 5px;
    padding: 0 1rem;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
}

.button-blue {
    border: 1px solid #1283af;
    background: none;
    color: #189fd4;
}
.button-blue:hover {
    opacity: 1;
    background: #189fd4;
    color: #fff;
}
.button-green {
    border: 1px solid #12af1a;
    background: none;
    color: #12af1a;
}
.button-green:hover {
    opacity: 1;
    background: #21db2b;
    color: #fff;
}
.button-red {
    border: 1px solid #af2a12;
    background: none;
    color: #d4361a;
}
.button-red:hover {
    opacity: 1;
    background: #d4361a;
    color: #fff;
}
.button-gray {
    color: #333;
    border: 1px solid #b1b1b1;
    background: #dbdbdb;
}
.button-content{

    display: flex;
    justify-content: flex-end;
}

.dialog {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border:  0px solid black;
}

.dialog-content {

    display: flex;
    border:  0px solid black;
    background-color: #eeece4;
}
.dialog-content .card-content {
    
    display: flex;
    flex-direction: column;
    margin-left: -.8rem;
    margin-right: -.8rem;
    margin-top: .5rem;
    padding: 0rem;
    border-radius: .3rem;
    width: 100%;
}
.dialog-content .pix-dialog{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: .2rem;
    margin-left: .5rem;
    margin-right: .5rem;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    width: 100%;
}


.dialog-content .dialog-input {

    display: flex;
    justify-content: stretch;
    margin: .5rem;
}
.dialog-content .div-column {

    display: flex;
    flex-direction: column;
    width: 100%;
    border:  0px solid black;
}
.dialog-content .div-row {

    display: flex;
    flex-direction: row;
    padding: .5rem;
}
.dialog-content .input-photo {

    display: flex;
    flex-direction: column;
    margin-top: .5rem;
}
.dialog-content .input-photo .input-label{
    margin-bottom: 0.25rem;
}
.dialog-item {

    display: flex;
    flex-direction: row;
    border:  1px solid black;
    margin: 1rem;
}
.dialog-title-left {

    text-align: left;
    padding:  10px;
    background-color: rgb(0, 185, 90);
    color: white;
    font-weight: bold;
}


.menu-itens {
    display: flex;
    flex-direction: row;
    margin: 0.25rem;
}
.menu {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.menu-itens {
    display: flex;
    flex-direction: row;
    align-items: center;
}


.dialog-title {
    text-align: center;
}

.form {

    display: flex;
    flex-direction: column;
}

.content-form {

    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: "Roboto", sans-serif;

}
.content-form .dialog-title {

    text-align: center;
    margin-bottom: 1rem;
}
.content-form .half-div {

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: .3rem;
}
.content-form .half-div .half-input  {
    width: 100%;
    justify-content: space-between;
}
.content-form .full-input {
    width: 98%;
    justify-content: space-between;
}
.content-form .form .form-div-title {

    margin: 0.rem 0;
    text-align: center;
}
.content-form .form .half-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.content-form .form .half-input{
    width: 49%;
    margin-bottom: .5rem;
}
.content-form .form .button-content{

    display: flex;
    justify-content: flex-end;
}
.ticket-value {
    font-family: "Roboto", sans-serif;
    font-size: 3.5rem;
    color: #0E773C;
}

.total-label {
    font-family: "Roboto", sans-serif;
    font-size: 2.5rem;
}

#header-container {

    display: flex;
    flex-direction: column;
    margin: .5rem;
    padding: .5rem;
    border-radius: .5rem;
    justify-content: flex-end;
}
#header-container .title-content {
    display: flex;
    flex-direction: column;
}
#header-container .history-filter-content1 {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
    border:  0px solid black;
    padding: 0rem;
}

#header-container .div-row {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    border:  0px solid black;
    margin:.1rem;
    padding: .5rem;
}

.full-input {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 5px;
}

.full-input .filter-itens{

    margin: 5px;
}

#header-container .div-column {

    display: flex;
    margin:.5rem;
    flex-direction: row;
    align-items: flex-end;
    border:  0px solid black;
}
.content-form .div-column{
    margin: 0rem;
    padding: .5rem;
}

@media(max-width: 1080px) {

    html {
        font-size: 93.75%;
    }
}

@media(max-width: 500px) {

    html {
        font-size: 85.5%;
    }

    .no-mobile-column {
        display: none !important;;
    }
}