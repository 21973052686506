#dashboard-container #grid-container {
    
    margin: 0.1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-right: 2rem;
}
#dashboard-container #history-content {

    display: flex;
    height: 100%;
    flex-direction: column;
    margin-top: 2rem;
    padding: .5rem;
    border-radius: .5rem;
}
#dashboard-container #history-content .no-result-content {

    display: flex;
    height: 45vh;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: .5rem;
}