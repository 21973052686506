#widget-container {
    border-radius: .5rem;
}

#widget-container .widget-card-content .content-value {
    text-align: center;
}

#widget-container .widget-card-action {
    justify-content: flex-end;
}