
.div-payment-info {
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: .2rem;
    margin-left: .5rem;
    margin-right: .5rem;
    margin-top: .5rem;
    margin-bottom: 1.5rem;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    width: 95%;
}

@media (max-width: 500px) {

    #header-container .history-filter-content1 {
        flex-direction: column !important;
    }
    
    #header-container .div-column {

        display: flex !important;
        justify-content: flex-end;
    }

    #header-container .div-row {

        flex-direction: column !important;
    }

    .full-input {

        display: flex !important;
        justify-content: space-between !important;
        flex-direction: column !important;
    }

    .full-input .filter-itens{
        margin: 8px !important;
    }

    .dialog .half-div .filter-itens{
        
        margin: 0px;
        margin-top: 10px;
        width: 100%;
    }
}