#myaccount-container {
    margin: 0;
    padding: 0;
}
#myaccount-container #image-container {
    margin: 0;
    padding: 0;
}
#myaccount-container #profile-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1rem 1rem;
    margin-bottom: 0.5rem;
}
#myaccount-container #profile-container .avatar-content {
    display: flex;
    background-color: #FAFAFA;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-top: -6.5rem;
    margin-right: 1.5rem;
}
#myaccount-container #profile-container .username {
    font-weight: 700;
    margin-top: -0.5rem;
}

.img {
    content: url('./accountImage.jpeg');
    background-repeat: no-repeat;
    height: 200px;
    width: 100%;
}

@media (max-width: 800px) {

    #myaccount-container #profile-container  {

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    #myaccount-container #profile-container .avatar-content {

        display: flex;
        background-color: #FAFAFA;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-top: -6rem;
        margin-right: 0;
        margin-bottom: 1rem;
    }

    .img {
        content: url('../App/assets/bg.jpg');
        background-repeat: no-repeat;
        height: "200px";
        width: 100%;
    }
}